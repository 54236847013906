/* eslint-disable vue/no-side-effects-in-computed-properties */
<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import moment from 'moment';

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Dashboard",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout},
  data() {
    return {
      currentpage : 1,
    }
  },
  computed: {
    packages () {
      return this.$store.getters["packages/packages"]
    },
    loading(){
        return this.$store.getters['packages/loading'];
    },
  },
  created(){
  },
  mounted(){
    this.$store.dispatch('packages/listing')
  },
  methods : {
    pagClick(){
        var offset = this.currentpage == 0 ? 0 : (this.currentpage * 10) -10
        this.$store.commit("packages/SET_OFFSET",offset);
        this.$store.dispatch("packages/listUser");
    },
  }

};
</script>

<template>
  <Layout>
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="mb-0 font-size-18">Packages </h4>

          <div class="page-title-right">
            <ol class="breadcrumb m-0">
              <li class="breadcrumb-item active">Tanggal : 05-Apr-2021 | Pukul : 03:35:34 , Selamat Malam</li>
            </ol>
          </div>
        </div>
        <div class="row" >
            <div class="col-md-12">
            <b-overlay :show="loading" rounded="sm">
                <div class="card"  with overlay :aria-hidden="loading ? 'true' : null">
                    <div class="card-body">
                        <div class="d-flex justify-content-between align-items-center">
                            <router-link tag="a" to="/packages/create" class="btn btn-success btn-sm" v-b-tooltip.hover title="Jalur orang dalam">Create Package</router-link>
                        </div>
                        <br>
                        <div class="table-responsive">
                            <table class="table table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th>Packages Name</th>
                                        <th>Storage</th>
                                        <th>Max Upload</th>
                                        <th>Max Workgroup</th>
                                        <th>Max Participants</th>
                                        <th>Stripe Id</th>
                                        <th>Price MOnth</th>
                                        <th>Price Year</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="list in packages.list" :key="list.id">
                                        <td>{{list.name_package}}</td>
                                        <td>{{list.basic.storage}}</td>
                                        <td>{{list.basic.max_upload}}</td>
                                        <td>{{list.workgroup.max_workgroup}}</td>
                                        <td>{{list.workgroup.max_participants}}</td>
                                        <td>{{list.stripe_id}}</td>
                                        <td>$ {{list.price_month}}</td>
                                        <td>$ {{list.price_year}}</td>    
                                        <td><span :class="`badge badge-pill ${list.ishidden ? 'badge-soft-success' : 'badge-soft-warning'} font-size-11`">{{list.ishidden ? "Hidden" : "Show"}}</span></td>
                                        <td>
                                            <router-link  tag="a" :to="`/packages/edit/${list._id}`" class="btn btn-warning btn-sm"><i class="bx bx-pencil"></i> Edit</router-link>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="d-flex justify-content-between"> 
                                <p>Total : {{packages.total}} </p>
                                <div class="overflow-auto">
                                    <div>
                                        <b-pagination
                                            :total-rows="packages.total"
                                            :per-page="10"
                                            first-number
                                            v-model="currentpage"
                                            @input="pagClick()"
                                        ></b-pagination>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-overlay>
            </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
